@charset "UTF-8";
.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
  visibility: hidden;
}

.request_wrap {
  width: 100%;
}
.request_wrap .tab_box {
  position: relative;
  float: left;
  width: 146px;
}
.request_wrap .tab_box > div {
  cursor: pointer;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: relative;
  z-index: 2;
  transition: all 0.2s ease-out;
  margin-bottom: 16px;
}
.request_wrap .tab_box > div.active {
  color: #fff;
}
.request_wrap .tab_box .active_tab {
  width: 146px;
  position: absolute;
  background: #5A46C2;
  box-shadow: 0px 4px 8px rgba(90, 70, 194, 0.26);
  border-radius: 4px;
  top: 0;
  z-index: 1;
}
.request_wrap .request_whiteBox {
  float: right;
  width: calc(100% - 146px - 24px);
  background: #fff;
  border-radius: 4px;
  padding: 24px 32px;
  display: flex;
  justify-content: space-between;
}
.request_wrap .request_whiteBox > div {
  width: 48.5%;
}
.request_wrap .request_whiteBox .send {
  background: url("../images/img_fileupload.svg") no-repeat top 56px center;
}
.request_wrap .request_whiteBox .send h4 {
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 206px;
}
.request_wrap .request_whiteBox .send .box1 {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}
.request_wrap .request_whiteBox .send .box1 label {
  cursor: pointer;
  display: inline-block;
  width: 15%;
  height: 32px;
  line-height: 32px;
  border: 1px solid #334D6E;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}
.request_wrap .request_whiteBox .send .box1 .file_label {
  width: 60%;
  border: 1px solid #C9C9C9;
  border-radius: 4px;
  padding-left: 16px;
}
.request_wrap .request_whiteBox .send .box1 input[type=file] {
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
}
.request_wrap .request_whiteBox .send .box1 button {
  cursor: pointer;
  width: 20%;
  background: #5A46C2;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
.request_wrap .request_whiteBox .send .box2 {
  margin-top: 24px;
  line-height: 160%;
}
.request_wrap .request_whiteBox .send .box2 p {
  font-weight: 600;
  font-size: 16px;
  color: #885AF8;
  padding-left: 24px;
  background: url("../images/icn_warn.svg") no-repeat left center;
}
.request_wrap .request_whiteBox .send .box2 ul {
  list-style: disc;
  padding-left: 24px;
}
.request_wrap .request_whiteBox .send .box2 ul li {
  font-size: 14px;
  color: #595959;
}
.request_wrap .request_whiteBox .checklist {
  position: relative;
  background: #F8F8F8;
  border-radius: 8px;
  padding: 40px;
  line-height: 160%;
}
.request_wrap .request_whiteBox .checklist p {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 12px;
}
.request_wrap .request_whiteBox .checklist ul {
  list-style: disc;
  padding-left: 24px;
}
.request_wrap .request_whiteBox .checklist ul li {
  font-size: 14px;
  color: #595959;
}
.request_wrap .request_whiteBox .checklist ul:not(:last-child) {
  margin-bottom: 16px;
}
.request_wrap .request_whiteBox .checklist button {
  position: absolute;
  bottom: 20px;
  right: 24px;
  cursor: pointer;
  width: 215px;
  height: 32px;
  background: #fff url("../images/icn_main_button_exel_24px.svg") no-repeat left 16px center;
  background-size: 20px;
  padding-left: 16px;
  border: 1px solid #0D9A56;
  border-radius: 4px;
  font-weight: 600;
  font-size: 15px;
  color: #334D6E;
}
.request_wrap .chk_Box_wrap {
  float: right;
  width: calc(100% - 146px - 24px);
}
.request_wrap .chk_Box_wrap .chk_whiteBox {
  width: 100%;
  padding: 32px 56px 24px;
  background: #fff;
  border-radius: 16px;
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div {
  display: flex;
  justify-content: space-between;
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div h4 {
  display: inline-block;
  margin-right: 12px;
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div input {
  height: 32px;
  border: 1px solid #90A0B7;
  border-radius: 4px;
  padding: 0 16px;
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div > div {
  display: flex;
  align-items: center;
  padding-bottom: 32px;
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div .hospi_name {
  position: relative;
  width: 27%;
  max-width: 356px;
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div .hospi_name input {
  width: calc(100% - 68px);
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div .hospi_name input::placeholder {
  color: #222;
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div .hospi_name .hospital {
  position: absolute;
  width: calc(100% - 68px);
  top: 32px;
  left: 68px;
  z-index: 12;
  max-height: 260px;
  overflow: auto;
  border-bottom: 1px solid #334D6E;
  /* 스크롤 바 */
  /* 스크롤 바 밑의 배경 */
  /* 실질적 스크롤 바 */
  /* 실질적 스크롤 바 위에 마우스를 올려다 둘 때 */
  /* 실질적 스크롤 바를 클릭할 때 */
  /* 스크롤 바 상 하단 버튼 */
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div .hospi_name .hospital::-webkit-scrollbar {
  width: 5.2px;
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div .hospi_name .hospital::-webkit-scrollbar-track {
  background-color: #fff;
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div .hospi_name .hospital::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 10px;
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div .hospi_name .hospital::-webkit-scrollbar-thumb:hover {
  background: #404040;
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div .hospi_name .hospital::-webkit-scrollbar-thumb:active {
  background: #808080;
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div .hospi_name .hospital::-webkit-scrollbar-button {
  display: none;
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div .hospi_name .hospital li {
  cursor: pointer;
  height: 56px;
  background: #fff url("../images/icn_auto_arrow.svg") no-repeat center right 8px;
  border: 1px solid #334D6E;
  padding: 0 32px 0 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div .hospi_name .hospital li:not(:first-child) {
  margin-top: -1px;
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div .hospi_name .hospital li p {
  color: #192A3E;
  font-weight: 400;
  line-height: 1.2;
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div .hospi_name .hospital li p span {
  font-size: 12px;
  display: block;
  color: #929292;
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div .select_period {
  width: 70%;
  max-width: 812px;
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div .select_period input {
  color: #222;
  width: calc(100% - 72px);
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div .select_period ul {
  width: calc(100% - 40px);
  display: grid;
  grid-template-columns: 30% 30% 10% 10% 10% 10%;
  grid-column-gap: 8px;
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div .select_period ul li button {
  cursor: pointer;
  width: 100%;
  height: 32px;
  background: #fff;
  border: 1px solid #90A0B7;
  border-radius: 4px;
  color: #90A0B7;
}
.request_wrap .chk_Box_wrap .chk_whiteBox > div .select_period ul li button.active {
  background: #A78CE7;
  border: 1px solid #A78CE7;
  color: #fff;
}
.request_wrap .chk_Box_wrap .chk_whiteBox .button_box {
  width: 100%;
  border-top: 1px solid #707683;
  display: flex;
  justify-content: right;
  padding-top: 16px;
}
.request_wrap .chk_Box_wrap .chk_whiteBox .button_box button {
  cursor: pointer;
  width: 136px;
  height: 40px;
  background: #5A46C2;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  color: #FFFFFF;
}
.request_wrap .all_top {
  margin: 32px 0 12px;
}
.request_wrap .all_top ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.request_wrap .all_top ul li {
  font-weight: 600;
  font-size: 16px;
  color: #595959;
}
.request_wrap .all_top ul li span {
  color: #885AF8;
}
.request_wrap .all_top ul li button {
  cursor: pointer;
  width: 188px;
  height: 32px;
  border: 1px solid #334D6E;
  border-radius: 4px;
  background: #fff url("../images/icn_page_download_24px.svg") no-repeat left 24px center;
  color: #334D6E;
  font-weight: 600;
  font-size: 14px;
  padding-left: 24px;
}
.request_wrap .table_container {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 64px - 24px - 177px - 76px - 32px - 32px - 48px);
  background-color: #fff;
}
.request_wrap .table_container .table {
  width: 100%;
}
.request_wrap .table_container .table .table_chk {
  width: 48px;
}
.request_wrap .table_container .table .table_chk label {
  margin-top: 4px;
}
.request_wrap .table_container .table th:not(:last-child),
.request_wrap .table_container .table td:not(:last-child) {
  border-right: 1px solid #e4e4e4;
}
.request_wrap .table_container .table thead tr {
  position: sticky;
  top: 0px;
  z-index: 10;
}
.request_wrap .table_container .table thead tr th {
  font-weight: 500;
  height: 32px;
  background: #505672;
  color: #fff;
}
.request_wrap .table_container .table thead tr th .chk_option + label {
  background: url("../images/icn_checkbox_white_inactive.svg");
}
.request_wrap .table_container .table thead tr th .chk_option:checked + label::after {
  background: url("../images/icn_checkbox_white_active.svg");
}
.request_wrap .table_container .table tbody tr {
  cursor: pointer;
}
.request_wrap .table_container .table tbody tr:hover {
  background: #F1F2F9;
}
.request_wrap .table_container .table tbody tr.active {
  background: #F1F2F9;
}
.request_wrap .table_container .table tbody tr td {
  height: 28px;
  text-align: center;
}
.request_wrap .table_container .table tbody tr td:last-child {
  text-align: left;
  padding: 0 16px;
}
.request_wrap .table_container .table thead tr,
.request_wrap .table_container .table tbody tr:not(:last-child) {
  border-bottom: 1px solid #e4e4e4;
}
.request_wrap .table_container .table .chk_option + label {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("../images/icn_checkbox_inactive.svg");
}
.request_wrap .table_container .table .chk_option:checked + label::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: url("../images/icn_checkbox_active.svg");
}
.request_wrap .pagenation_wrap {
  margin-top: 32px;
  width: 100%;
}
.request_wrap .pagenation_wrap ul {
  display: flex;
  justify-content: center;
}
.request_wrap .pagenation_wrap ul li {
  cursor: pointer;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #595959;
}
.request_wrap .pagenation_wrap ul li.rrear {
  background: url("../images/icn_ppagenation.svg") no-repeat center center;
  transform: rotate(180deg);
}
.request_wrap .pagenation_wrap ul li.rear {
  background: url("../images/icn_pagenation.svg") no-repeat center center;
  transform: rotate(180deg);
}
.request_wrap .pagenation_wrap ul li.ffront {
  background: url("../images/icn_ppagenation.svg") no-repeat center center;
}
.request_wrap .pagenation_wrap ul li.front {
  background: url("../images/icn_pagenation.svg") no-repeat center center;
}
.request_wrap .pagenation_wrap ul li:not(:last-child) {
  margin-right: 8px;
}
.request_wrap .pagenation_wrap ul li.active {
  background: #885AF8;
  border-radius: 4px;
  color: #FFFFFF;
  font-weight: 700;
}