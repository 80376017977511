@import url(https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css);
.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
  visibility: hidden;
}

.padding {
  padding: 0 80px;
}

.block {
  display: block;
}

.header {
  position: relative;
  width: 100%;
  height: 64px;
  background: #F1F2F9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header::after {
  content: "";
  display: block;
  width: calc(100% - 230px);
  height: 2px;
  background: #8C90A3;
  position: absolute;
  bottom: 0;
  right: 80px;
}

.header_left,
.header_right {
  display: flex;
  align-items: center;
}

.header_left {
  padding-top: 24px;
}

.header_right {
  padding-top: 14px;
}

.logo {
  width: 50px;
}

.user {
  height: 24px;
  line-height: 24px;
  padding-left: 32px;
  margin-right: 48px;
  color: #334D6E;
  font-weight: 600;
  font-size: 16px;
  background: url("../images/common/icn_header_user_24px.svg") no-repeat center left;
  background-size: 20px;
}

.logout {
  cursor: pointer;
}

.footer {
  width: 100%;
  height: 224px;
  background: #6C747F;
  color: #fff;
}

.footer_wrap {
  position: relative;
  height: 224px;
  margin: 0 auto;
  padding: 32px 80px 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
}

.footer_wrap dl {
  float: left;
}
.footer_wrap dl dd {
  width: 100%;
}
.footer_wrap dl dd:not(:last-child) {
  margin-bottom: 24px;
}

.footer_logo_box {
  cursor: pointer;
  position: absolute;
  right: 80px;
  bottom: 40px;
  width: 208px;
  height: 48px;
}
.footer_logo_box ul {
  display: flex;
  justify-content: space-between;
}
.footer_logo_box img.footer_logo {
  width: 52px;
}
.footer_logo_box img.footer_logo_white {
  width: 135px;
}

.login_back {
  position: relative;
  width: 100%;
  height: calc(100% - 224px);
  background: #F1F2F9;
}

.login_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 438px;
  min-height: 388px;
  overflow: hidden;
  padding: 32px 40px;
  background: linear-gradient(136.53deg, rgba(255, 255, 255, 0.7) 2.01%, rgba(201, 200, 255, 0.13) 99.29%);
  border: 1px solid #E4E4E4;
  border-radius: 32px;
}

.login_box_wrap {
  margin-top: 20px;
}

.HancomGX_Logo {
  width: 170px;
}

.login_id,
.login_pw {
  margin-top: 12px;
  width: 100%;
  height: 40px;
  border: 1px solid #334D6E;
  border-radius: 8px;
  padding: 0 24px;
  font-size: 16px;
}
.login_id::placeholder,
.login_pw::placeholder {
  font-weight: 600;
  color: #90A0B7;
}

.saveID_box {
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.saveID {
  cursor: pointer;
}
.saveID + label {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url("../images/icn_login_uncheck.svg");
}
.saveID:checked + label::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background: url("../images/icn_login_check.svg");
}

.saveIDLabel {
  cursor: pointer;
}

.saveIDTXT {
  color: #334D6E;
  font-weight: 700;
  font-size: 16px;
  padding-left: 12px;
}

.login_wrong {
  margin-top: 12px;
  font-size: 14px;
  line-height: 140%;
  color: #F7685B;
}

.login_btn {
  cursor: pointer;
  margin-top: 24px;
  width: 100%;
  height: 48px;
  background: #885AF8;
  border-radius: 8px;
  color: #fff;
  border: none;
  font-weight: 700;
  font-size: 18px;
}

.mainGX {
  padding-top: 24px;
  padding-bottom: 48px;
  width: 100%;
  min-height: calc(100% - 64px);
  background: #F1F2F9;
}

.tab_box {
  margin: 4px 0 0 20px;
  width: 100%;
  height: 32px;
}
.tab_box > div {
  cursor: pointer;
  position: relative;
  float: left;
  width: 110px;
  height: 100%;
  line-height: 32px;
  background: #E6E6E6;
  color: #929292;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  border-top: 1px solid transparent;
}
.tab_box > div.active {
  background: #fff;
  color: #505672;
  font-weight: 600;
  border: 1px solid #222;
  border-bottom: none;
}

.option_box {
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 16px;
  padding: 12px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.option_box_cont1 {
  display: flex;
  width: 50%;
  height: 80px;
}
.option_box_cont1 ul {
  width: 100%;
}
.option_box_cont1 ul li {
  float: left;
  width: 33.3333333333%;
  height: 50%;
  display: flex;
  align-items: center;
}
.option_box_cont1 ul li span {
  width: 62px;
  font-weight: 600;
  font-size: 14px;
}
.option_box_cont1 ul li input {
  padding: 0 16px;
  width: calc(100% - 90px);
  max-width: 280px;
  height: 32px;
  background: #fff;
  border: 1px solid #90A0B7;
  border-radius: 4px;
}

.option_box_cont2 {
  width: 21.4285714286%;
  height: 80px;
  min-width: 340px;
}
.option_box_cont2 ul {
  width: 100%;
  height: 33.3333333333%;
  display: flex;
  align-items: center;
}
.option_box_cont2 ul li {
  float: left;
  font-weight: 600;
  font-size: 14px;
  margin-right: 14px;
}

.chk_txt {
  margin-right: 18px;
}

.chk_boxes {
  display: flex;
  align-items: center;
}

.chk_option + label {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("../images/icn_checkbox_inactive.svg");
}
.chk_option:checked + label::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: url("../images/icn_checkbox_active.svg");
}

.chk_Label {
  cursor: pointer;
}

.chk_LabelTxt {
  color: #595959;
  margin-left: 2px;
}

.option_box_cont3 {
  width: 28.5714285714%;
  max-width: 436px;
  display: flex;
  justify-content: space-between;
}

.option_box_btn {
  cursor: pointer;
  width: 33.3333333333%;
  max-width: 140px;
  margin-right: 12px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  color: #595959;
  background: #ECECEF;
  border-radius: 8px;
  transition: all 0.1s;
}
.option_box_btn:hover {
  background: #E4E4E4;
}

.btn_box {
  width: 66.6666666667%;
  max-width: 284px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.btn_box button {
  cursor: pointer;
  width: calc(50% - 2px);
  max-width: 140px;
  height: 32px;
  border: 1px solid #C9C9C9;
  border-radius: 4px;
  color: #334D6E;
  font-weight: 600;
  font-size: 15px;
  padding-left: 16px;
  transition: all 0.1s;
}
.btn_box button:nth-child(1) {
  background: #fff url("../images/icn_main_button_viewer_24px.svg") no-repeat center left 16px;
  background-size: 20px;
}
.btn_box button:nth-child(2) {
  background: #fff url("../images/icn_main_button_print_24px.svg") no-repeat center left 16px;
  background-size: 20px;
}
.btn_box button:nth-child(3) {
  background: #fff url("../images/icn_main_button_pdf_24px.svg") no-repeat center left 16px;
  background-size: 20px;
}
.btn_box button:nth-child(4) {
  background: #fff url("../images/icn_main_button_exel_24px.svg") no-repeat center left 16px;
  background-size: 20px;
}
.btn_box button:hover {
  background-color: #F8F8F8;
}

.noresult {
  margin-top: 120px;
  width: 100%;
  text-align: center;
}
.noresult > img {
  width: 204px;
}
.noresult > p {
  font-weight: 700;
  font-size: 20px;
  color: #90A0B7;
}

.tables_wrap {
  margin-top: 24px;
  width: 100%;
  height: calc(100vh - 216px - 48px);
}

.table_box1 {
  float: left;
  width: 60%;
}

.table_box2 {
  height: 100%;
  float: right;
  width: calc(40% - 24px);
}

.table_box1_top {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  color: #192A3E;
  height: 32px;
  margin-bottom: 6px;
}

.table_tit1 {
  margin-right: 24px;
  padding-left: 28px;
  background: url("../images/icn_main_patientlist_20px.svg") no-repeat center left;
}

.table_tit2 {
  height: 32px;
  line-height: 32px;
  margin-bottom: 6px;
  background: url("../images/icn_main_patieninfo_20px.svg") no-repeat center left;
}

.table_container {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 216px - 32px - 6px - 48px);
  background-color: #fff;
}

.table_container2 {
  width: 100%;
  overflow: auto;
  height: 100%;
  background-color: #fff;
}

.listGx {
  width: 2312px;
  font-size: 14px;
}
.listGx th:not(:last-child),
.listGx td:not(:last-child) {
  border-right: 1px solid #e4e4e4;
}
.listGx thead tr {
  position: sticky;
  top: 0px;
  z-index: 10;
}
.listGx thead tr th {
  font-weight: 500;
  height: 32px;
  background: #505672;
  color: #fff;
}
.listGx thead tr th .chk_option + label {
  background: url("../images/icn_checkbox_white_inactive.svg");
}
.listGx thead tr th .chk_option:checked + label::after {
  background: url("../images/icn_checkbox_white_active.svg");
}
.listGx tbody tr {
  cursor: pointer;
}
.listGx tbody tr:hover {
  background: #F1F2F9;
}
.listGx tbody tr.active {
  background: #F1F2F9;
}
.listGx tbody tr td {
  height: 28px;
  text-align: center;
}
.listGx tbody tr td.orange {
  color: #DE551A;
}
.listGx tbody tr td.blue {
  color: #481EAF;
}
.listGx tbody tr td.green {
  color: #005033;
}
.listGx tbody tr td.emer {
  background: url("../images/icn_datatable_emer_24px.svg") no-repeat center center;
}
.listGx tbody tr td.state {
  width: 88px;
}
.listGx tbody tr td.hold {
  background: url("../images/img_datatable_status_1.svg") no-repeat center center;
}
.listGx tbody tr td.start {
  background: url("../images/img_datatable_status_2.svg") no-repeat center center;
}
.listGx tbody tr td.request {
  background: url("../images/img_datatable_status_3.svg") no-repeat center center;
}
.listGx tbody tr td.middle {
  background: url("../images/img_datatable_status_4.svg") no-repeat center center;
}
.listGx tbody tr td.final {
  background: url("../images/img_datatable_status_5.svg") no-repeat center center;
}
.listGx tbody tr td.L {
  color: #0C73BE;
}
.listGx tbody tr td.A {
  color: #2ABB75;
}
.listGx tbody tr td.H {
  color: #F8463B;
}
.listGx tbody tr td.memo {
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 8px;
}
.listGx thead tr,
.listGx tbody tr:not(:last-child) {
  border-bottom: 1px solid #e4e4e4;
}

.hide_letter {
  text-indent: -9999px;
}

.table_chk {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info_box {
  width: 100%;
  background: #fff;
  border: 1px solid #9180FF;
  border-radius: 16px;
  padding: 16px 30px;
}

.info_name {
  color: #885AF8;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 12px;
}
.info_name span {
  margin-left: 8px;
  font-weight: 600;
  font-size: 14px;
  color: #222;
}

.info_table {
  width: 100%;
  text-align: left;
}
.info_table tr {
  height: 25px;
  color: #595959;
}
.info_table th {
  height: 100%;
  font-weight: 600;
  font-size: 14px;
}
.info_table td {
  font-size: 14px;
}
.info_table thead tr th,
.info_table thead tr td {
  padding: 2px 0 12px;
  border-bottom: 2px solid #d9d9d9;
}
.info_table tbody tr:first-child th, .info_table tbody tr:first-child td {
  padding: 12px 0 8px;
}

.info_table_box {
  padding-bottom: 16px;
}

.info_table_etc {
  margin: 0 auto;
  width: 94%;
}
.info_table_etc th {
  font-weight: 600;
}
.info_table_etc th,
.info_table_etc td {
  font-size: 12px;
  color: #595959;
}
.info_table_etc tbody tr:first-child th, .info_table_etc tbody tr:first-child td {
  padding: 8px 0 8px;
}

.listGxbaby {
  width: 100%;
  min-width: 644px;
}
.listGxbaby tbody tr {
  cursor: auto;
}
.listGxbaby tbody tr:hover {
  background: inherit;
}
.listGxbaby tr th:nth-child(1) {
  width: 92px;
  max-width: 92px;
}
.listGxbaby tr th:nth-child(2) {
  width: 144px;
  max-width: 144px;
}
.listGxbaby tr th:nth-child(3), .listGxbaby tr th:nth-child(4), .listGxbaby tr th:nth-child(5), .listGxbaby tr th:nth-child(6), .listGxbaby tr th:nth-child(7) {
  width: 64px;
  max-width: 64px;
}
.listGxbaby tr td:nth-child(2) {
  width: 144px;
  max-width: 144px;
  text-align: left;
  padding: 0 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.listGxbaby tbody tr td.product {
  font-weight: 700;
  color: #885AF8;
  font-size: 15px;
}
.listGxbaby tbody tr td.disease {
  font-weight: 700;
  font-size: 14px;
}
.listGxbaby tbody tr td.nameEtc {
  font-size: 13px;
  color: #898989;
  letter-spacing: -1px;
}

.pdf_wrap {
  width: 100%;
  height: 100%;
}

.viewer {
  aspect-ratio: 3/4;
  height: 100%;
  margin: 0 auto;
  display: block;
}

.circle_loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
}
.circle_loader > div {
  height: 10px;
  width: 10px;
  background-color: #885AF8;
  border-radius: 50%;
  position: absolute;
  -webkit-animation: 0.8s opaque ease-in-out infinite both;
  animation: 0.8s opaque ease-in-out infinite both;
}
.circle_loader > div:nth-child(1) {
  top: -25px;
  left: 0;
}
.circle_loader > div:nth-child(2) {
  top: -17px;
  left: 17px;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.circle_loader > div:nth-child(3) {
  top: 0;
  left: 25px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.circle_loader > div:nth-child(4) {
  top: 17px;
  left: 17px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.circle_loader > div:nth-child(5) {
  top: 25px;
  left: 0;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.circle_loader > div:nth-child(6) {
  top: 17px;
  left: -17px;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.circle_loader > div:nth-child(7) {
  top: 0;
  left: -25px;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.circle_loader > div:nth-child(8) {
  top: -17px;
  left: -17px;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.progress_bar {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  margin-top: -16px;
}
.progress_bar .bar_inner {
  position: relative;
  width: 400px;
  height: 240px;
  background: #fff;
  display: grid;
  place-items: center;
}
.progress_bar .bar_inner p {
  position: absolute;
  text-align: center;
  bottom: 32px;
}

.popup {
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
}

.popup > * + * {
  margin-left: 24px;
}

.is_new_popup {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.is_new_popup .btn {
  cursor: pointer;
  margin: 40px auto 0;
  background: #885AF8;
  color: #fff;
  width: 64px;
  height: 32px;
  border-radius: 4px;
}

@-webkit-keyframes opaque {
  0% {
    opacity: 0.1;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.1;
  }
}
@keyframes opaque {
  0% {
    opacity: 0.1;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.1;
  }
}